import { render, staticRenderFns } from "./PermissionsOverview.vue?vue&type=template&id=60cbd899"
import script from "./PermissionsOverview.vue?vue&type=script&lang=ts&setup=true"
export * from "./PermissionsOverview.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60cbd899')) {
      api.createRecord('60cbd899', component.options)
    } else {
      api.reload('60cbd899', component.options)
    }
    module.hot.accept("./PermissionsOverview.vue?vue&type=template&id=60cbd899", function () {
      api.rerender('60cbd899', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/iam/PermissionsOverview.vue"
export default component.exports